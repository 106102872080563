.table-slides {
  margin: 12rem 0;

  @include tablet {
    margin: 8rem 0;
  }

  @include mobile {
    margin: 4rem 0;
  }
  &__header {
    padding: 12rem 0 0;
    text-align: center;
    border-top: 0.1rem solid #070A21;

    @include tablet {
      padding: 8rem 0 0;
    }

    @include mobile {
      padding: 4rem 0 0;
    }

    h2 {
      margin: 2.4rem 0 0;
    }

    p {
      margin: 2.4rem auto 0;
      max-width: 49.4rem;
    }
  }

  &__nav {
    display: none;

    @include tablet {
      display: flex;
      justify-content: center;
      margin: 3.2rem 0 0;
    }
  }

  &__slider-mobile {
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__slider {
    @include tablet {
      display: none;
    }
  }

  &__wrap {
    margin: 8rem 0 0;

    @include tablet {
      display: flex;
      margin: 4rem 0 0;
    }
  }

  &__mobile {
    width: 50%;
    flex: none;
    display: none;

    @include tablet {
      display: flex;
    }

    @include mobile {
      width: calc(100% / 3 * 2);
    }

    &__item {
      width: calc(100% / 2);
      text-align: center;
      display: grid;
      grid-template-rows: 9.2rem 10.6rem 8rem 8rem 8rem 8rem 8rem;

      @include tablet {
        flex: none;
      }

      &--is-active {
        border: 0.1rem solid var(--blue);
        border-radius: 0.8rem;

        .table-slides__mobile__item__row {
          &:nth-child(1) {
            background-color: var(--blue);
            color: var(--white);
            border-radius: 0.7rem;
          }

          &:nth-last-child(1) {
            border-radius: 0.7rem;
          }

        }
      }

      &:nth-child(1) {
        .table-slides__slider__item__row {
          border-top-left-radius: 0.8rem;
          border-bottom-left-radius: 0.8rem;
        }
      }

      &:nth-last-child(1) {
        .table-slides__slider__item__row {
          border-top-right-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
        }
      }

      &__row {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        padding: 2rem 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }

        @include tablet {
          border-radius: 0.8rem;
        }
        &:nth-child(odd) {
          background-color: #F5F6FE;
        }

        &:nth-child(1) {
          font-weight: 600;
        }

        img {
          margin: 0 auto;
          width: 3.2rem;
        }
      }

      &:nth-child(1) {
        text-align: left;

        .table-slides__mobile__item__row {
          font-weight: 500;
          justify-content: flex-start;

          &:nth-child(1) {
            font-weight: 600;
          }
        }
      }
    }
  }

  &__slider, &__slider-mobile {

    width: 100%;

    @include tablet {
      width: 50%;
    }

    @include mobile {
      width: calc(100% / 3);
    }

    &__item {
      width: calc(100% / 6);
      text-align: center;
      display: grid;
      grid-template-rows: 9.2rem 10.6rem 8rem 8rem 8rem 8rem 8rem;

      @include tablet {
        flex: none;
      }

      &--is-active {
        border: 0.1rem solid var(--blue);
        border-radius: 0.8rem;

        .table-slides__slider__item__row {
          &:nth-child(1) {
            background-color: var(--blue);
            color: var(--white);
            border-radius: 0.7rem;
          }

          &:nth-last-child(1) {
            border-radius: 0.7rem;
          }

        }
      }

      &:nth-child(1) {
        .table-slides__slider__item__row {
          border-top-left-radius: 0.8rem;
          border-bottom-left-radius: 0.8rem;
        }
      }

      &:nth-last-child(1) {
        .table-slides__slider__item__row {
          border-top-right-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
        }
      }

      &__row {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.4rem;
        padding: 2rem 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }

        @include tablet {
          border-radius: 0.8rem;
        }
        &:nth-child(odd) {
          background-color: #F5F6FE;
        }

        &:nth-child(1) {
          font-weight: 600;
        }

        img {
          margin: 0 auto;
          width: 3.2rem;
        }
      }

      &:nth-child(1) {
        text-align: left;

        @include tablet {
          text-align: center;
        }
        .table-slides__slider__item__row {
          font-weight: 500;
          justify-content: flex-start;

          @include tablet {
            justify-content: center;
            font-weight: 400;
          }

          &:nth-child(1) {
            font-weight: 600;
          }
        }
      }
    }
  }
}


.modal-content {
  .modal-table-slides {
    margin: 12rem 0;
  
    @include tablet {
      margin: 8rem 0;
    }
  
    @include mobile {
      margin: 4rem 0;
    }
    &__header {
      padding: 12rem 0 0;
      text-align: center;
      border-top: 0.1rem solid #070A21;
  
      @include tablet {
        padding: 8rem 0 0;
      }
  
      @include mobile {
        padding: 4rem 0 0;
      }
  
      h2 {
        margin: 2.4rem 0 0;
      }
  
      p {
        margin: 2.4rem auto 0;
        max-width: 49.4rem;
      }
    }
  
    &__slider, &__slider-mobile {
  
      width: 100%;
  
      &__item {
        width: calc(100% / 3);
        text-align: center;
        display: grid;
        grid-template-rows: 8rem 8rem 8rem 8rem 8rem 8rem 8rem 8rem 10rem 8rem 8rem 10rem 8rem 8rem 8rem 8rem;

        @include tablet {
          grid-template-rows: 10rem 10rem 10rem 10rem 10rem 10rem 10rem 10rem 14rem 10rem 10rem 13rem 12rem 10rem 10rem 10rem;
        }
  
        &--is-active {
          border: 0.1rem solid var(--blue);
          border-radius: 0.8rem;
  
          .modal-table-slides__slider__item__row {
            &:nth-child(1) {
              background-color: var(--blue);
              color: var(--white);
              border-radius: 0.7rem;
            }
  
            &:nth-last-child(1) {
              border-radius: 0.7rem;
            }
  
          }
        }
  
        &:nth-child(1) {
          .modal-table-slides__slider__item__row {
            border-top-left-radius: 0.8rem;
            border-bottom-left-radius: 0.8rem;
          }
        }
  
        &:nth-last-child(1) {
          .modal-table-slides__slider__item__row {
            border-top-right-radius: 0.8rem;
            border-bottom-right-radius: 0.8rem;
          }
        }
  
        &__row {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.4rem;
          padding: 2rem 1.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
  
          @include mobile {
            font-size: 1.2rem;
            line-height: 1.6rem;
          }
  
          @include tablet {
            border-radius: 0.8rem;
          }
          &:nth-child(odd) {
            background-color: #F5F6FE;
          }
  
          &:nth-child(1) {
            font-weight: 600;
          }
  
          img {
            margin: 0 auto;
            width: 3.2rem;
          }
        }
  
        &:nth-child(1) {
          text-align: left;
  
          @include tablet {
            text-align: center;
          }
          .table-slides__slider__item__row {
            font-weight: 500;
            justify-content: flex-start;
  
            @include tablet {
              justify-content: center;
              font-weight: 400;
            }
  
            &:nth-child(1) {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}