@import '../../node_modules/swiper/swiper.scss';

:root {
  --black: #070A21;
  --dark: #2B2F40;
  --black2: #24294E;
  --blue: #424CEA;
  --grey: #F3F7FA;
  --white: #FFFFFF;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 1.6rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}


::-moz-selection {
  /* Code for Firefox */
  color: var(--white);
  background: var(--blue);
}

::selection {
  color: var(--white);
  background: var(--blue);
}

html {
  font-size: 62.5%;
}

.container {
  width: 100%;
  max-width: 128rem;
  margin: 0 auto;
  padding: 0 4rem;

  @include extra-large {
    max-width: 180rem;
  }

  @include tablet {
    padding: 0 60px;
  }

  @include mobile {
    padding: 0 2.4rem;
  }
}

main {
  overflow: hidden;
  background-color: var(--white);
}

button {
  cursor: pointer;
}

.table-wrap {
  overflow: auto;
  table {
    th,td {
      padding: 2.4rem 4rem;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 2.4rem;
      vertical-align: middle;
    }

    th {
      font-weight: bold;
    }

    tr {
      th,td {
        &:nth-child(1) {
          text-align: left;
        }
      }
    }

    tr:nth-child(odd) {

      th,td {
        background-color: #F5F6FE;
        &:nth-child(1) {
          border-top-left-radius: 0.8rem;
          border-bottom-left-radius: 0.8rem;
        }

        &:nth-last-child(1) {
          border-top-right-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
        }
      }
    }
  }
}

.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s ease;
    &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        background-color: rgba(0,0,0,0.5);
    }

    &-content {
        background-color: #fefefe;
        width: 80%;
        max-height: 90vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.95);
        border-radius: 12px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        display: flex;
        flex-direction: column;

        @include tablet {
            width: 90%;
        }

        .modal.active & {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &-close {
        position: sticky;
        width: 28px;
        height: 28px;
        font-size: 28px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.2s ease;
        margin-left: auto;
        z-index: 2;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        
        &:hover {
            opacity: 1;
        }
    }

    // Specific styles for the table modal
    &-table-slides__wrap {
        overflow-y: auto;
        max-height: calc(90vh - 60px); // Account for close button space
        padding: 20px;
        
        // Hide scrollbar for Chrome, Safari and Opera
        &::-webkit-scrollbar {
            display: none;
        }
        
        // Hide scrollbar for IE, Edge and Firefox
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    &-table-slides__slider {
        position: relative;
    }

    &-table-slides__slider__item {
        &__row {
            padding: 12px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}
